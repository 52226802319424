export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '准确分析实时数据',
    headline: 'GlowPulse',
    description:
      'GlowPulse是一款智能体育信息应用，致力于帮助您快速轻松地获取您热衷的活动信息。无论您是体育迷还是活动爱好者，GlowPulse都是专为您量身定制，以满足您的需求',
    buttonLabel: '下载！',
    imgStart: '',
    img: require('../../images/logo.png'),
    alt: '信用卡',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '​​【精彩触觉】',
    headline: '即时理解',
    // description:
    //   "标题2",
    // buttonLabel: '了解更多',
    imgStart: '',
    img: require('../../images/wisdom.png'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '主要特点',
    headline:
      'Glowpulse 主要功能',
    description:
      "1.使用直观的问答方式即时查询活动信息，包括赛程、比赛结果和实时战况。",
    description2:
      "2.提供最新的体育新闻和活动更新，确保您不会错过任何重要时刻。",
    description3:
      "3.涵盖多个体育和联赛，包括足球、篮球、网球、棒球等领域。",
    description4:
      "4.支持个性化定制，并根据您的兴趣和偏好推荐相关活动。",
    buttonLabel: '查看详情',
    imgStart: 'start',
    img: require('../../images/svg-1.svg'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: '​​【体育决策】',
    headline: '专业咨询',
    // description:
    //   '应用说明3',
    // buttonLabel: '立即注册',
    imgStart: 'start',
    img: require('../../images/insights.png'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjFive = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true, 
    topLine: '​​【智能专家】',
    headline: '互动问答',
    // description:
    //   'App Explanation 3',
    // buttonLabel: 'Sign Up Now',
    imgStart: '',
    img: require('../../images/pa.png'),
    alt: 'Vault',
    start: ''
  };
  export const homeObjSix = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: '​​【活动数据】',
    headline: '一手掌握',
    // description:
    //   'App Explanation 3',
    // buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/datacontrol.png'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjSeven = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true, 
    topLine: '​​【极速互动】',
    headline: '智能体育',
    // description:
    //   'App Explanation 3',
    // buttonLabel: 'Sign Up Now',
    imgStart: '',
    img: require('../../images/experience.png'),
    alt: 'Vault',
    start: ''
  };